<template>
    <div class="modal" v-if="modelValue" :class="{active : modelValue}">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>{{ isPlan ? 'Plan' : 'Workout' }}<span>Unassign Contacts</span></h1>
                </div>
                <button class="close_btn left_out" @click="closeModal"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <div class="setting_wpr">
                    <Form @submit="handleSubmit" v-slot="{ errors }" ref="workout-unassign-contacts-form">
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Contact(s)</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.contacts }">
                                    <Field autocomplete="off" name="contacts" v-model="form.contacts" rules="required">
                                        <multiselect
                                            v-model="form.contacts"
                                            label="name"
                                            value-prop="id"
                                            mode="tags"
                                            :options="contacts"
                                            :searchable="true"
                                            :internal-search="false"
                                            :closeOnSelect="false"
                                            :clearOnSelect="false"
                                            @search-change="searchContact"
                                            placeholder="Select Contacts.."
                                            trackBy="search_key"
                                        >
                                            <template v-slot:option="{ option }">
                                                <div class="multiselect-avatar">
                                                    <img class="character-option-icon" :src="option.photo">
                                                </div>
                                                <div class="multiselect-title-content">
                                                    <div class="multiselect-title">{{ option.name }}</div>
                                                    <div class="multiselect-sub-title">{{ option.email }}</div>
                                                </div>
                                            </template>
                                        </multiselect>
                                    </Field>
                                </div>
                                <ErrorMessage name="contacts" class="text-danger" />
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
            <div class="modal_footer">
                <button type="button" :disabled="loader" class="btn cancel_btn" @click="closeModal">Cancel</button>
                <button type="button" :disabled="loader" class="btn save_btn" @click="handleSubmit"><i class="fa fa-spinner fa-spin" v-if="loader"></i> {{ loader ? 'Unassigning' : 'Unassign' }}</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    import { Field, Form, ErrorMessage } from 'vee-validate'

    export default {
        name: 'Workout Plan Unassign Contact',

        data () {
            return {
                form: {
                    contacts: [],
                    ids: []
                },
            }
        },

        props: {
            modelValue: Boolean,
            ids: Array,
            refreshAsset: {
                type: Function,
                default: () => {},
            },
            isPlan: {
                type: Boolean,
                default: true,
            }
        },

        emit: ['update:modelValue'],

        watch: {
            modelValue (value) {
                const vm = this;

                vm.toggleScrollBar(value);

                if (value) {
                    vm.getAllContacts({});
                    vm.form.contacts = [];
                    vm.form.ids      = vm.ids;
                }
            },
        },

        components: {
            Form,
            Field,
            ErrorMessage,
        },

        computed: mapState ({
            contacts: state => state.contactModule.allContacts,
            loader: state => state.workoutModule.workoutPlanComponentLoader,
        }),

        methods: {
            ...mapActions({
                getAllContacts: 'contactModule/getAllContacts',
                unassignContacts: 'workoutModule/unassignContacts',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            searchContact (search) {
                const vm = this;

                vm.getAllContacts({ search });
            },

            handleSubmit () {
                const vm = this;

                const form = vm.$refs['workout-unassign-contacts-form'];

                form.validate().then((result) => {
                    if (result.valid) {
                        vm.form.assetType     = vm.isPlan ? 'plan' : 'workout';
                        vm.form.setFieldError = form.setFieldError;

                        vm.unassignContacts(vm.form).then((result) => {
                            if (result) {
                                vm.refreshAsset();
                                vm.closeModal();
                            }
                        });
                    }
                });
            }
        }
    }
</script>
